import React from "react";
import humanizeString from "humanize-string";
import { MenuItemType } from "@/libs/hooks/useMenu";
import { MenuItem } from "./MenuItem";

type MenuProps = {
  menu: MenuItemType[] | undefined;
  lang: string;
  isLoading: boolean;
};

type GroupedMenuItems = {
  [key: string]: MenuItemType[];
};

const Menu = ({ menu, lang, isLoading }: MenuProps) => {
  if (isLoading) {
    // Use aria-live to announce the loading status dynamically
    return (
      <div aria-live="assertive" aria-busy="true">
        Loading...
      </div>
    );
  }

  if (!menu) {
    // Use role="alert" to inform the user about the error
    return <div role="alert">Something went wrong...</div>;
  }

  const groupedItems = menu
    .filter((item) => item.attributes.showOnMainPage)
    .sort((a, b) => a.id - b.id)
    .reduce<GroupedMenuItems>((acc, item) => {
      const type = item.attributes.type;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(item);
      return acc;
    }, {});

  return (
    <div className="mt-8">
      {Object.keys(groupedItems).map((type) => (
        <React.Fragment key={type}>
          <section id={`menu-${type}`}>
            <h2 className="w-full text-center text-6xl uppercase">
              {humanizeString(type)}
            </h2>
            <div className="flex-1 grid md:grid-cols-3 align-bottom grid-cols-1 gap-5 py-4">
              {groupedItems[type].map((item) => (
                <MenuItem
                  key={item.id}
                  {...item.attributes}
                  ingredients={item.attributes?.ingredientsJson?.[lang]}
                />
              ))}
            </div>
          </section>
        </React.Fragment>
      ))}
    </div>
  );
};

export { Menu };
