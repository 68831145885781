import { useMainDict } from "@/libs/hooks/useTranslation";
import Link from "next/link";
import React from "react";

interface TermsAndPoliciesLinksProps {
  showTerms?: boolean;
  showPrivacy?: boolean;
  showPromotion?: boolean;
  className?: string;
}

export const TermsAndPoliciesLinks: React.FC<TermsAndPoliciesLinksProps> = ({
  showTerms = true,
  showPrivacy = true,
  showPromotion = true,
  className = "",
}) => {
  const { t } = useMainDict();
  return (
    <div
      className={`flex flex-col md:flex-row items-center mt-5 gap-3 underline text-pizza-papaya ${className} z-[120]`}
    >
      {showTerms && (
        <Link href="/terms-and-conditions" className="flex">
          {t("termsAndConditions")}
        </Link>
      )}
      {showPrivacy && (
        <Link href="/privacy-policy" className="flex">
          {t("privacyPolicy")}
        </Link>
      )}
      {showPromotion && (
        <Link href="/promotions" className="flex">
          {t("promotionPolicy")}
        </Link>
      )}
    </div>
  );
};
