import { Carousel } from "flowbite-react";
import Image from "next/image";

export const MainCarousel: React.FC = () => {
  return (
    <div className="h-[500px] md:w-[600px] w-full">
      <Carousel slideInterval={1000} aria-label="Gallery" id="main-carousel">
        <Image
          src="/DSC_7340.jpg"
          width={500}
          height={500}
          alt="Describe image 1 here"
          priority
        />
        <Image
          src="/DSC_9863.jpg"
          width={500}
          height={500}
          alt="Describe image 2 here"
          priority
        />
        <Image
          src="/DSC_9867.jpg"
          width={500}
          height={500}
          alt="Describe image 3 here"
          priority
        />
        <Image
          src="/DSC_0009.jpg"
          width={500}
          height={500}
          alt="Describe image 4 here"
          priority
        />
      </Carousel>
    </div>
  );
};
