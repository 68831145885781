import useGAnalytics, {
  EventAction,
  EventCategory,
  EventLabel,
} from "@/libs/hooks/tracking/useGAnalytics";
import useVisibilityTracker from "@/libs/hooks/tracking/useVisibilityTracking";
import { PromotionsType, usePromotions } from "@/libs/hooks/usePromotions";
import { useMainDict } from "@/libs/hooks/useTranslation";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { IconArrowLeft } from "@tabler/icons-react";
import { Accordion } from "flowbite-react";
import Image from "next/image";
import Link from "next/link";

import { isAfter, isBefore, isSameDay, isWithinInterval } from "date-fns";

type PromotionsProps = {
  showLinks?: boolean;
  openPolicy?: boolean;
  backButton?: boolean;
};

export const PromotionsSection: React.FC<PromotionsProps> = ({
  openPolicy = true,
  showLinks = true,
  backButton = true,
}) => {
  const { t } = useMainDict();
  const { promotions } = usePromotions();

  const { trackEvent } = useGAnalytics();

  useVisibilityTracker("promotions-section", () => {
    trackEvent(
      "promotions-section-visible",
      EventCategory.USER_INTERACTION,
      EventAction.BECOMES_VISIBLE,
      EventLabel.BANNER
    );
  });

  if (
    !promotions ||
    !promotions.attributes.promotions ||
    promotions.attributes.promotions.length <= 0
  ) {
    return null;
  }

  const isPromotionVisible = (promotion: any) => {
    const now = new Date();
    if (promotion.validFrom && promotion.validTo) {
      return isWithinInterval(now, {
        start: new Date(promotion.validFrom),
        end: new Date(promotion.validTo),
      });
    }
    if (promotion.validFrom) {
      return (
        isAfter(now, new Date(promotion.validFrom)) ||
        isSameDay(now, new Date(promotion.validFrom))
      );
    }
    if (promotion.validTo) {
      return (
        isBefore(now, new Date(promotion.validTo)) ||
        isSameDay(now, new Date(promotion.validTo))
      );
    }
    return true; // Visible if there are no date constraints
  };

  return (
    <div
      id="promotions-section"
      aria-labelledby="promotions-heading"
      className={`w-full  bg-pizza-papaya 
      text-black flex flex-col items-center justify-center ${
        backButton && "min-h-screen"
      }
    px-4 `}
    >
      <div className="flex w-full md:max-w-4xl flex-col mt-11 mb-4">
        <div className="flex w-full">
          {backButton && (
            <Link
              href="/"
              className="flex items-center justify-center h-8 w-8 rounded-full bg-pizza-yellow-darker text-white  hover:bg-opacity-100 bg-opacity-75"
            >
              <IconArrowLeft />
            </Link>
          )}
          <h1 className="font-futura text-center text-5xl md:text-6xl font-bold text-shadow-md uppercase mb-4 w-full">
            {promotions.attributes.title}
          </h1>
        </div>
        <div className="w-full  divide-dashed divide-pizza-gray grid grid-cols-1 md:grid-cols-2 gap-4 text-justify">
          {promotions.attributes.promotions
            .filter(isPromotionVisible)
            .sort((a, b) => (a.order || 0) - (b.order || 0))
            .map((promotion) => {
              return (
                <div key={promotion.name} className="p-2">
                  {promotion.image && (
                    <div className="flex justify-center">
                      <Image
                        unoptimized={true}
                        alt={promotion.image?.altText}
                        src={promotion.image?.imageUrl}
                        height={promotion.image?.height}
                        width={promotion.image?.width}
                        className="max-h-48 md:max-h-80 w-auto h-full"
                      />
                    </div>
                  )}
                  <h2 className="text-3xl font-sans font-bold my-3">
                    {promotion.name}
                  </h2>
                  <BlocksRenderer
                    blocks={{
                      "list-item": ({ children }) => (
                        <li className="list-disc list-item list-inside">
                          {children}
                        </li>
                      ),
                    }}
                    content={promotion.description}
                  />
                </div>
              );
            })}
        </div>
        <div className="w-full text-justify mt-8">
          <Accordion
            className="w-full bg-transparent mt-4 border-gray-700"
            collapseAll={!openPolicy}
          >
            <Accordion.Panel className="w-full hover:bg-transparent border-gray-700">
              <Accordion.Title
                className="text-black bg-transparent hover:bg-transparent focus:ring-0 border-gray-700"
                aria-expanded="false"
              >
                {t("promotionPolicy")}
              </Accordion.Title>
              <Accordion.Content className="text-black">
                <BlocksRenderer
                  blocks={{
                    "list-item": ({ children }) => (
                      <li className="list-disc list-item list-inside">
                        {children}
                      </li>
                    ),
                  }}
                  content={promotions.attributes.text}
                />
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>

        {showLinks && (
          <>
            <p className="mt-5 font-bold">Links</p>
            <ul className="list-none mb-4">
              {promotions.attributes.links.map((link) => (
                <li className="list-item" key={link.label}>
                  <Link className="underline text-blue-800" href={link.href}>
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
