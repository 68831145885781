import { useEffect, useRef } from "react";

const useVisibilityTracker = (elementId: string, onVisible: () => void) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const observedElement = document.getElementById(elementId);

    if (observedElement) {
      observerRef.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            onVisible();
          }
        },
        {
          threshold: 0.1, // Trigger when 10% of the element is in view
        }
      );

      observerRef.current.observe(observedElement);
    }

    return () => {
      if (observerRef.current && observedElement) {
        observerRef.current.unobserve(observedElement);
      }
    };
  }, [elementId, onVisible]);
};

export default useVisibilityTracker;
