import { appConfig } from "@/libs/appConfig";
import useGAnalytics, {
  EventAction,
  EventCategory,
  EventLabel,
} from "@/libs/hooks/tracking/useGAnalytics";
import useVisibilityTracker from "@/libs/hooks/tracking/useVisibilityTracking";
import { useIngredients } from "@/libs/hooks/useIngredients";
import { useSettings } from "@/libs/hooks/useSettings";
import { useMainDict } from "@/libs/hooks/useTranslation";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Accordion } from "flowbite-react";
import Image from "next/image";

export const IngredientsSection = () => {
  const { settings } = useSettings();
  const { ingredients } = useIngredients();

  const { trackEvent } = useGAnalytics();
  const { t } = useMainDict();

  useVisibilityTracker("ingredients-section", () => {
    trackEvent(
      "ingredients-section-visible",
      EventCategory.USER_INTERACTION,
      EventAction.BECOMES_VISIBLE,
      EventLabel.INGREDIENTS
    );
  });

  if (!settings?.showIngredients) {
    return null;
  }

  return (
    <section
      id="ingredients-section"
      aria-labelledby="ingredients-heading"
      className="bg-pizza-blue w-full justify-center flex"
    >
      <div className="flex-1 flex justify-center w-full items-center lg:max-w-7xl lg:min-h-full min-h-72 text-pizza-papaya h-full align-center flex-col p-4">
        <h2
          id="ingredients-heading"
          className="font-futura text-center text-5xl md:text-6xl font-bold text-shadow-md uppercase mb-4"
        >
          {t("ingredientsHeader")}
        </h2>
        <div className="grid grid-cols-1 gap-4 md:max-w-[450px] px-2">
          {ingredients?.map((item) => (
            <div
              className="flex flex-col items-center text-justify"
              key={item.id}
            >
              <Image
                unoptimized={true}
                alt={item.attributes.name || "Ingredient image"}
                className="drop-shadow-2xl sm:max-w-[2/3] md:max-w-[350px]"
                src={appConfig.assetsUrl + item.attributes.imageUrl}
                width={350}
                height={350}
              />
              <p className="font-sans font-black text-3xl text-center w-full mt-1 mb-2">
                {item.attributes.name}
              </p>
              <BlocksRenderer content={item.attributes.description} />
              {item.attributes.readMoreTitle && (
                <Accordion className="w-full bg-transparent mt-4" collapseAll>
                  <Accordion.Panel className="w-full hover:bg-transparent">
                    <Accordion.Title
                      className="text-pizza-papaya bg-transparent hover:bg-transparent focus:ring-0"
                      aria-expanded="false"
                    >
                      {item.attributes.readMoreTitle}
                    </Accordion.Title>
                    <Accordion.Content className="text-pizza-papaya">
                      <BlocksRenderer
                        content={item.attributes.readMoreDescription}
                      />
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
