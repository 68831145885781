import useSWR from "swr";
import { appConfig } from "../appConfig";
import { useMainDict } from "./useTranslation";
import { fetcher } from "../fetcher";
import { BlocksContent } from "@strapi/blocks-react-renderer";
import { StrapiNextImageType } from "@/types";

export type PromotionsType = {
  id: number;
  attributes: {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    title: string;
    links: { href: string; label: string; target: string }[];
    promotions: {
      name: string;
      description: BlocksContent;
      validFrom: string;
      validTo: string;
      image: StrapiNextImageType;
      order?: number;
    }[];
    text: BlocksContent;
    bannerImage: StrapiNextImageType;
  };
};

type PromotionsResponseType = {
  data: PromotionsType;
  meta: {};
};

const getApiRoute = (language: string) =>
  `/promotions-policy?locale=${language}&populate[bannerImage]=*&populate[promotions][populate][image]=*&populate[links]=*`;

export async function preFetchPromotions(language: string) {
  try {
    const apiRoute = getApiRoute(language);

    const data = await fetcher<PromotionsResponseType>(apiRoute);

    return { [apiRoute]: data };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function usePromotions() {
  const { language } = useMainDict();

  const { data, isLoading } = useSWR<PromotionsResponseType>(
    getApiRoute(language)
  );

  return { promotions: data?.data, isLoading };
}
