import useSWR from "swr";
import { appConfig } from "../appConfig";
import { useMainDict } from "./useTranslation";
import { BlocksContent } from "@strapi/blocks-react-renderer";
import { fetcher } from "../fetcher";

export type IngredientsResponse = {
  data: [
    {
      id: number;
      attributes: {
        name: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        description: BlocksContent;
        imageUrl: string;
        readMoreTitle: string;
        readMoreDescription: BlocksContent;
      };
    }
  ];
  meta: {};
};

const getIngredientsApiRoute = (language: string) =>
  `/ingredients?locale=${language}`;

export async function preFetchIngredients(language: string) {
  try {
    const apiRoute = getIngredientsApiRoute(language);

    const data = await fetcher<IngredientsResponse>(apiRoute);

    return { [apiRoute]: data };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function useIngredients() {
  const { language } = useMainDict();

  const { data, isLoading } = useSWR<IngredientsResponse | IngredientsResponse>(
    getIngredientsApiRoute(language)
  );

  return { ingredients: data?.data, isLoading };
}
