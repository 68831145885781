import { useOpeningHours } from "@/libs/hooks/useOpeningHours";
import { useMainDict } from "@/libs/hooks/useTranslation";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { IconClock } from "@tabler/icons-react";
import React from "react";

export const OpeningHoursModal = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { t } = useMainDict();

  const { openingHours } = useOpeningHours();

  return (
    <>
      <a
        aria-label={t("openingHours")}
        onClick={onOpen}
        className="w-full cursor-pointer uppercase md:w-auto flex items-center justify-center transition-all ease-in duration-75 active:shadow-inner bg-pizza-pistachio dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75 px-5 py-2.5  text-center mr-2 mb-3"
      >
        <IconClock className="mr-3 animate-pulse" aria-hidden="true" />
        {t("openingHours")}
      </a>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="bg-pizza-pistachio"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-center uppercase  text-pizza-papaya  text-3xl">
                {t("openingHours")}
              </ModalHeader>
              <ModalBody>
                {openingHours?.isOpenNow && (
                  <p className="text-center  font-sans font-bold  animate-pulse text-pizza-gray   text-bold">
                    {t("weAreOpen")}
                  </p>
                )}
                <div className="grid grid-cols-2 gap-3 mb-5">
                  {openingHours?.data.attributes.days.map((day) => {
                    return (
                      <React.Fragment key={day.day}>
                        <p className="text-right font-bold uppercase mr-3 text-pizza-papaya">
                          {day.day}
                        </p>
                        <p className="text-left text-pizza-papaya">
                          {day.openingTime && day.closingTime
                            ? `${day.openingTime.substring(
                                0,
                                5
                              )} - ${day.closingTime.substring(0, 5)}`
                            : t("closed")}
                        </p>
                      </React.Fragment>
                    );
                  })}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
