import useGAnalytics, {
  EventAction,
  EventCategory,
  EventLabel,
} from "@/libs/hooks/tracking/useGAnalytics";
import useVisibilityTracker from "@/libs/hooks/tracking/useVisibilityTracking";
import { useMainDict } from "@/libs/hooks/useTranslation";
import Image from "next/image";

export const FollowOnInstagramSection = () => {
  const { t } = useMainDict();

  const { trackEvent } = useGAnalytics();

  useVisibilityTracker("follow-on-insta-section", () => {
    trackEvent(
      "follow-on-insta-section-visible",
      EventCategory.USER_INTERACTION,
      EventAction.BECOMES_VISIBLE
    );
  });
  
  return (
    <section
      id="follow-on-insta-section"
      className="bg-pizza-pistachio w-full justify-center flex lg:flex-row flex-col-reverse"
      aria-labelledby="follow-us-on-instagram"
    >
      <Image
        src={"/DSC_9987.jpg"}
        width={500}
        height={500}
        alt={t("instagramImageAlt")}
        className="w-auto"
      />
      <div className="py-4 flex-1 justify-center items-center flex text-shadow-md">
        <p
          id="follow-us-on-instagram"
          className="text-center text-4xl lg:text-7xl font-hand text-pizza-papaya py-16 "
        >
          {t("followUs")}{" "}
          <a
            href="https://www.instagram.com/pizzicletta.krk/"
            target="_blank"
            rel="noreferrer"
            className="underline"
            aria-label={t("followOnInstagramAriaLabel")}
          >
            @pizzicletta.krk
          </a>
        </p>
      </div>
    </section>
  );
};
