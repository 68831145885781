import React, { useState, useEffect } from "react";
import { IconArrowBarToUp } from "@tabler/icons-react";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const toggleVisibility = () => {
    setShowButton(window.scrollY > 500);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-3 left-1/2 transform -translate-x-1/2 bg-pizza-yellow-darker text-white p-2 
      rounded-full text-sm cursor-pointer hover:shadow-inner hover:opacity-100 opacity-80 transition-all duration-300 ${
        showButton
          ? "translate-y-0 opacity-100"
          : "translate-y-14 opacity-0 pointer-events-none"
      }`}
      style={{ transitionProperty: "transform, opacity" }}
      aria-label="Scroll to top"
    >
      <IconArrowBarToUp className="animate animate-pulse" />
    </button>
  );
};

export default ScrollToTopButton;
