import {
  IconBrandFacebook,
  IconBrandFacebookFilled,
  IconBrandInstagram,
  IconBrandTiktok,
} from "@tabler/icons-react";
import Image from "next/image";
import { TermsAndPoliciesLinks } from "./TermsAndPoliciesLinks";

export const Footer = () => {
  return (
    <footer className="p-4 bg-pizza-gray sm:p-6 dark:bg-gray-900 text-gray-100 ">
      <div className="md:flex md:justify-between w-full">
        <div className="mb-6 md:mb-0 w-full flex items-center flex-col text-overline-2">
          <Image
            src={"/newest.png"}
            width={100}
            height={100}
            alt="Pizzicletta logo"
          />
        </div>
      </div>
      <div className="flex  space-x-6 justify-center mt-4">
        <a
          href="https://www.facebook.com/pizzicletta.krk/"
          target={"_blank"}
          rel="noreferrer"
          className="text-gray-500 hover:text-gray-200 dark:hover:text-white"
          aria-label="Follow us on Facebook"
        >
          <IconBrandFacebookFilled />
          <span className="sr-only">Facebook page</span>
        </a>
        <a
          href="https://www.instagram.com/pizzicletta.krk"
          target={"_blank"}
          rel="noreferrer"
          className="text-gray-500 hover:text-gray-200 dark:hover:text-white"
          aria-label="Follow us on Instagram"
        >
          <IconBrandInstagram />
          <span className="sr-only">Instagram page</span>
        </a>

        <a
          href="https://www.tiktok.com/@pizzicletta.krk"
          target={"_blank"}
          rel="noreferrer"
          className="text-gray-500 hover:text-gray-200 dark:hover:text-white"
          aria-label="Follow us on TikTok"
        >
          <IconBrandTiktok />
          <span className="sr-only">TikTok page</span>
        </a>
      </div>
      <TermsAndPoliciesLinks className="w-full justify-center !text-gray-300" />
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <a href="https://pizzicletta.net/" className="hover:underline">
            Pizzicletta™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};
