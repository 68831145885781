import { appConfig } from "@/libs/appConfig";
import Image from "next/image";

export type MenuItemProps = {
  name: string;
  ingredients: string;
  imageUrl: string;
  price: number;
  extraLabel: string;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  name,
  ingredients,
  imageUrl,
  price,
  extraLabel,
}) => {
  return (
    <div className="flex flex-col items-center mb-7">
      <p className="font-sans font-black text-3xl text-center w-full mb-1">
        {name}
      </p>
      <p className="font-sans italic text-xl text-center w-full mb-4">
        {extraLabel}
      </p>
      {imageUrl && (
        <Image
          unoptimized={true}
          alt={name}
          src={appConfig.assetsUrl + imageUrl}
          width={450}
          height={450}
          className="w-auto"
        />
      )}
      {!imageUrl && (
        <Image
          alt="margherita"
          src={"/pizza-placeholder.svg"}
          width={400}
          height={240}
          className="w-auto max-h-[240px]"
        />
      )}
      <div className=" flex-1"></div>
      <ul className="text-left text-xl font-semibold vertical-center list-disc">
        {ingredients?.split(" · ").map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      <div className="flex-1"></div>
      {price && (
        <p className="text-2xl font-extrabold text-center mt-4">{price} zł</p>
      )}
    </div>
  );
};
