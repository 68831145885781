// hooks/useMenu.ts
import useSWR from "swr";
import { appConfig } from "../appConfig";
import { fetcher } from "../fetcher";

export type MenuItemType = {
  id: number;
  attributes: {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    ingredientsJson: {
      [key: string]: string;
    };
    availableFrom: string;
    availableTo: string;
    name: string;
    imageUrl: string;
    showOnMainPage: boolean;
    price: number;
    type: string;
    extraLabel: string;
  };
};

type MenuResponseType = {
  data: MenuItemType[];
  meta: {};
};

const MenusApiRoute = `/menu-items`;

export async function preFetchMenu() {
  try {
    const data = await fetcher<MenuResponseType>(MenusApiRoute);

    return { [MenusApiRoute]: data };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function useMenu() {
  const { data, isLoading: isMenuLoading } = useSWR<
    MenuResponseType | MenuResponseType
  >(MenusApiRoute);

  return { menu: data?.data, isMenuLoading };
}
