import useSWR from "swr";
import { useMainDict } from "./useTranslation";
import { fetcher } from "../fetcher";
import { isWithinInterval, parse, getDay } from "date-fns";
import getT from "next-translate/getT";

type TranslatedOpeningSession = {
  id: number;
  day: string;
  openingTime: string;
  closingTime: string;
};

type OpeningSession = {
  id: number;
  day: number;
  openingTime: string;
  closingTime: string;
};

export type OpeningHoursResponseDataType = {
  id: number;
  attributes: {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    days: OpeningSession[];
    specialDays: {
      date: string;
      note: string;
      isOpen: boolean;
      sessions: OpeningSession;
    }[];
    temporaryClosures: { startDate: string; endDate: string; reason: string }[];
  };
};

type OpeningHoursResponseType = {
  data: OpeningHoursResponseDataType;
  meta: {};
};

const getApiRoute = () => `/opening-hour?populate=*`;

export async function preFetchOpeningHours(language: string) {
  try {
    const t = await getT(language, "main");
    const apiRoute = getApiRoute();

    const response = await fetcher<OpeningHoursResponseType>(apiRoute);

    const data = {
      ...response,
      data: {
        ...response.data,
        attributes: {
          ...response.data.attributes,
          days: response.data.attributes.days.map(
            (session: OpeningSession): TranslatedOpeningSession => ({
              ...session,
              day: t(`days.${session.day}`),
            })
          ),
        },
      },
    };

    return { [apiRoute]: data };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function useOpeningHours() {
  const { t } = useMainDict();

  const { data, isLoading } = useSWR<OpeningHoursResponseType>(getApiRoute());

  const isOpenNow = (openingSessions: OpeningSession[]): boolean => {
    const today = new Date();
    const dayOfWeek = getDay(today); // Sunday is 0, Monday is 1, and so on.

    // Adjusting dayOfWeek to match the API's format (Monday is 1, Sunday is 7)
    const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

    const todaySessions = openingSessions?.find(
      (session) => session.id === adjustedDayOfWeek
    );

    if (!todaySessions) {
      return false;
    }

    if (!todaySessions.openingTime && !todaySessions.closingTime) {
      return isWithinInterval(today, { start: "Closed", end: "Closed" });
    }

    const openingTime = parse(
      todaySessions.openingTime,
      "HH:mm:ss",
      new Date()
    );
    const closingTime = parse(
      todaySessions.closingTime,
      "HH:mm:ss",
      new Date()
    );

    return isWithinInterval(today, { start: openingTime, end: closingTime });
  };

  const translatedAndCheckedData = data
    ? {
        ...data,
        isOpenNow: isOpenNow(data.data.attributes.days),
        data: {
          ...data.data,
          attributes: {
            ...data.data.attributes,
            days: data.data.attributes.days.map(
              (session: OpeningSession): TranslatedOpeningSession => ({
                ...session,
                day: t(`days.${session.day}`),
              })
            ),
          },
        },
      }
    : null;

  return { openingHours: translatedAndCheckedData, isLoading };
}
