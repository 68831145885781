import { useMainDict } from "@/libs/hooks/useTranslation";
import { MainCarousel } from "./Carousel";
import Link from "next/link";
import { useSettings } from "@/libs/hooks/useSettings";
import useGAnalytics, {
  EventAction,
  EventCategory,
  EventLabel,
} from "@/libs/hooks/tracking/useGAnalytics";

export const WelcomeSectionComponent = () => {
  const { t } = useMainDict();
  const { settings } = useSettings();

  const { trackEvent } = useGAnalytics();

  const handleOurStoryLinkClick = () => {
    trackEvent(
      "our-story-link-clicked",
      EventCategory.LINKS,
      EventAction.CLICK,
      EventLabel.OUR_STORY
    );
  };

  return (
    <section className="bg-pizza-red w-full justify-center flex  gap-3 ">
      <div className="max-w-7xl flex lg:flex-row flex-col">
        <MainCarousel />
        <div className="flex-1 lg:justify-start justify-center ">
          <div className="flex-1 lg:max-w-xl lg:min-h-full min-h-72 text-pizza-papaya h-full flex align-center flex-col justify-center p-4 font-sans font-extralight gap-2">
            <p className="text-justify text-3xl mt-2">
              {t("welcome.microWorld")}
            </p>
            <p className="text-justify text-3xl mt-2">{t("welcome.4mins")}</p>
            <p className="text-left text-3xl mt-2">{t("welcome.flavors")}</p>
            <p className="text-justify text-3xl mt-2">
              {t("welcome.ingredients")}
            </p>
            {settings?.showOurStoryLink && (
              <Link
                href="/our-story"
                type="button"
                onClick={handleOurStoryLinkClick}
                className="text-pizza-papaya  w-auto uppercase
                bg-pizza-yellow-darker font-bold outline-1
                 outline-gray-50 active:shadow-inner rounded-full
                  text-sm px-5 py-2.5 text-center "
              >
                <p className="animate animate-pulse font-futura">
                  {t("readOurStory")}
                </p>
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
